.contact-icon {
    font-size: 130px;
    border-radius: 50%;
    border: solid 10px var(--bodybg);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
    padding: 5px;
    background-color: var(--bodybg);
    margin: 0 auto;
}

.contact {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
}

form {
    padding: 2rem 4rem;
    text-align: left;
}

.form-field {
    width: 100%;
    font-size: 90%;
    padding: 5px;
    background-color: var(--cardbg);
    color: var(--primaryfont);
}

label {
    margin: 1rem 0 5px 0;
    display: inline-block;
}

.error {
    color: var(--errortext);
}

.success {
    color: var(--highlighttext);
}

.form-btn {
    margin-right: 20px;
}

@media screen and (min-width: 576px) {
   
    .contact {
        padding: 0 5%;
    }
}

@media screen and (min-width: 576px) {
   
    .contact {
        padding: 0 10%;
    }
}

@media screen and (min-width: 1200px) {
   
    .contact {
        padding-left: 200px;
        padding-right: 10%;
    }

    .contact h1 {
        text-align: left;
        padding-left: 4rem;
    }

}

@media screen and (min-width: 1600px) {

    .contact {
        padding-left: 250px;
    }
}