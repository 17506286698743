header {
    background-color: var(--headerbg);
    color: var(--headercolor);
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 2;
}

#menu-icon {
    color: var(--headercolor);
    background-color: var(--headerbg);
    font-size: 40px;
    border-radius: 5px;
    padding: 5px;
    vertical-align: middle;
    height: 50px;
}

#menu-icon:hover {
    background-color: var(--headercolor);
    color: var(--headerbg);
}

#header-left {
    padding: 0 20px;
    width: 200px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

#header-center {
    padding: 0;
    /* flex-grow: 2; use if nav is in center container*/
}

#header-right {
    padding: 0 20px;
    width: 200px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 2;
    gap: 20px;
    /* remove flex-grow if nav is moved to center container */
}


#logo {
    width: 40px;
    border-top: 2px solid var(--headercolor);
    border-bottom: 2px solid var(--headercolor);
    padding: 3px;
    vertical-align: middle;
    text-align: center;
    font-size: 30px;
    color: var(--headercolor);
    height: auto;
}

.nav {
    list-style-type: none;
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    /* convert to justify-content: center if nav is moved to center container */
    width: 100%;
    font-size: 22px;
}

.nav-item {
    padding: 0 2vw;
}

.nav-link {
    color: var(--headercolor);
    font-weight: 400;
}

.nav-link:hover {
    color: var(--headerhover);
}

.active {
    color: var(--headerhover);
}

.btn {
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 80%;
    /* width: 80px; */
    background: var(--btnbg);
    border: solid 3px var(--btnborder);
    color: var(--primaryfont);
}

.btn:hover {
    background-color: var(--btnbghover);
    border: solid 3px var(--btnborder);
    cursor: pointer;
    color: var(--btntexthover);
}

.mobile-nav {
    background-color: var(--headerbg);
    width: 100vw;
    height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    left: 0;
    padding: 2rem 4rem;
    z-index: 2;
}

.mobile-nav-item {
    font-size: 150%;
    list-style-type: none;
    padding: 1rem 0;
}

.mobile-nav-item:hover {
    color: var(--headerhover);
}

.mobile-links-container {
    margin-top: 6rem;
}

.mobile-social-link {
    font-size: 2em;
    color: var(--headercolor);
    margin: 10px;
    text-decoration: none;
    transition: transform .7s ease-in-out;
}
    
.mobile-social-link:hover,
.mobile-social-link:focus {
    transform: rotate(360deg);
    color: var(--headerhover);
    cursor: pointer;
}

/* theme icons */
.nav-theme {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.nav-theme:hover,
.nav-theme:active {
    cursor: pointer;

}

.theme-menu {
    position: absolute;
    top: 0px;
    background-color: white;
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 20%);
    border-radius: 16px;
    padding-top: 50px;
    z-index: 3;
}

.theme-menu-icon {
    margin: 5px;
    font-size: 30px;
}

.nav-theme:hover .theme-icon {
    transform: rotate(360deg);
    transition: transform 1s ease-in-out;
    box-shadow: none;
}

.theme-icon {
    /* font-size: 30px; */
    transform: rotate(-25deg);
    transition: transform 1s ease-in-out;
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 20%);
    border-radius: 50%;
    background-color: var(--cardbg);
    z-index: 4;
    color: var(--btnbghover);
}

.theme-open {
    box-shadow: none;
    background-color: white;
}

.theme-def {
    color: #EE6352;
}

.theme-bw {
    color: black;
}

.theme-dark {
    color: #061724;
}


@media screen and (min-width: 1200px) {
    header {
        display: grid;
        grid-template-columns: 330px 1fr 2fr;
    }

    #header-left {
        padding: 0 20px;
        width: 100%;
    }

    #header-right,
    #header-center {
        background-color: var(--bodybg);
        width: 100%;
        height: 100%;
    }

    .nav-link {
        color: var(--primaryfont);
    }
    
    .nav-link:hover {
        color: var(--navactive);
    }
    
    .active {
        color: var(--navactive);
    }
    
}

@media screen and (min-width: 1850px) {
    header {
        max-width: 1850px;
    }
}