* {
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
}

:root {
  --headerbg: #EE6352;
  --headercolor: white;
  --bodybg: whitesmoke; 
  --cardbg: white;
  --titlefont: #0c0c0c;
  --primaryfont: #363020;
  --headerhover: #363020;
  --footerhover: #EE6352;
  --secondaryfont: white;
  --errortext: #C42021;
  --navactive: #EE6352;
  --highlighttext: #157145;
  --btnborder:#EE6352;
  --btnbg:white;
  --btnbghover:#EE6352;
  --btntexthover:white;
  --tabbg: #EE6352;
  --tabicon:  #363020;
  --tabiconactive: #363020;
}

.bw-mode {
  --headerbg: black;
  --headercolor: white;
  --bodybg: whitesmoke; 
  --cardbg: white;
  --titlefont: #0c0c0c;
  --primaryfont: #0c0c0c;
  --secondaryfont: white;
  --headerhover: #cccccc;
  --footerhover: #363020;
  --errortext: #C42021;
  --navactive: #157145;
  --highlighttext: #157145;
  --btnborder:black;
  --btnbg:white;
  --btnbghover:black;
  --btntexthover: white;
  --tabicon:  white;
  --tabiconactive: black;
}

.dark-mode {
  --headerbg: #061724;
  --headercolor: #E3E3E3;
  --bodybg: #0C2233; 
  --cardbg: #061724;
  --titlefont: #eeeeee;
  --primaryfont: #D6D6D6;
  --secondaryfont: #0c0c0c;
  --headerhover: #03DAC6;
  --footerhover: #03DAC6;
  --errortext: #fc7979;
  --navactive: #03DAC6;
  --highlighttext: #03DAC6;
  --btnborder:#03DAC6;
  --btnbg:#061724;
  --btnbghover:#03DAC6;
  --btntexthover: #061724;
  --tabicon:  #D6D6D6;
  --tabiconactive: #03DAC6;
}

html {
  overflow-y: scroll;
}

body {
  /* setting a font size for all child elements that can then be relatively referenced */
  font-size: 20px;
  /* sets the space between lines of text */
  line-height: 1.2;
  font-family: 'Barlow Semi Condensed', Tahoma, Verdana, sans-serif;
  color: var(--primaryfont);
}

h1 {
  color: var(--titlefont);
}

.container {
  height: auto;
  width: 100%;
  background: var(--bodybg);
}

main {
  background-color: var(--bodybg);
  min-height: calc(100vh - 80px);
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.isFixed {
  position: fixed;
}


