footer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: var(--secondaryfont);
    margin-top: 20px;
}

.social-link {
    font-size: 2em;
    color: var(--secondaryfont);
    margin: 10px;
    text-decoration: none;
    transition: transform .7s ease-in-out;
}

/* micro animation for social links */
.social-link:hover,
.social-link:focus {
    transform: rotate(360deg);
    color: var(--headerhover);
    cursor: pointer;
}

.footer-mobile .social-link {
    color: var(--titlefont);
}

.footer-mobile p {
    color: var(--titlefont);
}

.footer-mobile .social-link:hover {
    color: var(--footerhover);
}

@media screen and (min-width: 1200px) {
    footer {
        align-items: flex-start;
        color: var(--headercolor);
        margin-top: 20px;
        position: fixed;
        bottom: 0;
        padding: 1rem 2rem;
        width: 100%;
    }

    .social-link {
        color: var(--headercolor);
        margin: 10px 30px 10px 0;
    }
}
