.portfolio-intro {
    text-align: center;
    padding: 0 2rem;
    margin: 1rem 0 4rem 0;
}

.work-section {
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    text-align: left;    
    gap: 4rem;
    margin: 2rem 0;
}


.work-card {
    flex: 1 0 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.work-description {
    display: block;
    padding: 1rem 0 1rem 2rem;
    text-align: left;
    transition: text-align 7s ease-in-out;
}

.work-description li {
    line-height: 1.4;
}

.work-description ul {
    padding-bottom: 20px;
    color: var(--primaryfont);
    font-size: 90%;
}

.card-icon {
    display: none !important;
}

.work-pic-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    max-height: 400px;
}

.work-pic {
    margin: 0 auto;
    width: 90%; 
    border: solid 2px var(--primaryfont);
    height: auto;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    object-fit: cover;
    object-position: 50% 0;
    border-radius: 5px;
}

figcaption {
    width: 90%;
    flex: 1 0 100%;
    color: var(--primaryfont);
    padding: 20px 0;
    font-size: 90%;
}

h3 {
    color: var(--primaryfont);
}

.sub {
    color: var(--highlighttext);
    font-size: 90%;
    padding-bottom: 0.5rem;
}

.work-btn {
    font-size: 80%;
    padding: 5px 1rem;
    margin-right: 1rem;
    border: solid 1px var(--btnborder);
    border-radius: 5px;
    background-color: var(--btnbg);
    color: var(--primaryfont);
    display: inline-block;
}

.work-btn:hover {
    background-color: var(--btnbghover);
    color: var(--secondaryfont);
    cursor: pointer;
}

.work-btn-disabled:hover {
    cursor: default;
    background-color: var(--btnbg);
    color: var(--primaryfont);
}

@media screen and (min-width: 768px) {

    .portfolio-intro {
        padding: 0 5rem;
    }

    .work-pic-container {
        max-height: 450px;
    }

    .work-card {
        max-width: 720px;
        padding: 40px 20px;
        border: 1px solid var(--cardbg);;
        border-radius: 10px;
        background-color: var(--cardbg);
        box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 20%);
    }

}


@media screen and (min-width: 1200px) {

    .work-section {
        gap: 2rem;
        align-content: flex-start;
    }

    .portfolio-intro {
        padding: 0 2rem;
    }

    .work-pic-container {
        height: auto;
    }

   figcaption {
        padding: 2rem 1rem;
        width: 90%;
        transition: 1s ease;
        text-align: center;
    }

    .work-description {
        display: none;
        padding: 1rem 0 1rem 2rem;
    }

    .transparent {
        transition: 1s ease;
        height:auto;
    }

    .displayed {
        height: 100%;
        transition: 1s ease;
    }

    .card-icon {
        position: absolute;
        bottom: -20px;
        left: calc(50% - 18px);
        display: block !important;
        border: none;
        border-radius: 50%;
        color: var(--primaryfont) !important;
        box-shadow: none;
        text-decoration: none;
        transition: transform .7s ease-in-out;
        font-size: 2em;
        background: none;
        height: 36px;
    }

    .card-icon:hover {
        color: var(--btnbghover) !important; 
        cursor: pointer;
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 1200px) {
   
    .portfolio-container {
        padding-left: 220px;
    }

    .portfolio-intro {
        text-align: left;
    }

    .portfolio-h1 {
        text-align: left;
    }

    .work-card {
        align-items: flex-start;
        max-width: 700px;
        padding: 40px;
    }

    .work-pic-container {
        height: 400px;
    }

    .work-pic {
        margin: 0;
        width: 100%;
        object-position: 50% 0;
    }

    figcaption {
        width: 100%;
        padding-top: 30px;
    }
}

@media screen and (min-width: 1600px) {
   
    .portfolio-container {
        padding-left: 250px;
    }

    .portfolio-intro {
        margin-bottom: 2rem;
    }

    .work-section-wide {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2rem;
    }

    .work-card {
        max-width: 560px;
        padding: 20px 20px 40px 20px;
        height: auto;
    }

    .work-pic-container {
        height: auto;
        max-height: 350px;
    }
}