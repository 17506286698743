.split-content {
    position: relative;
    width: 100%;
}

.container-top {
    flex: initial;
    width: 100%;
    min-height: 250px;
    display: flex;
    justify-content: center;
    background-color: var(--headerbg);
    position: relative;
    margin-bottom: 150px;
}

.container-bottom {
    /* flex: initial; */
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
}

.main-img {
    border-radius: 50%;
    max-width: 250px;
    margin-bottom: 30px;
    border: solid 10px var(--bodybg);
    position: absolute;
    top: 50%;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}

.main-icon {
    font-size: 250px;
    background-color: var(--bodybg);
    padding: 40px;
}

h1 {
    font-weight: 800;
    padding: 5px 0;
    font-size: 250%;
    text-align: center;
    width: 100%;
}

h2 {
    font-weight: 800;
    padding: 5px 0;
    font-size: 180%;
    text-align: center;
    width: 100%;
}

h3 {
    font-weight: 600;
    font-size: 115%
}

h4 {
    font-weight: 500;
}

p {
    font-weight: 300;
    padding: 5px 0;
    font-size: 90%;
}

ul {
    font-weight: 300;
}

.sub-header {
    text-transform: uppercase;
    font-size: 90%;
    color: var(--navactive);
}

@media screen and (max-height: 700px) {

    .main-img {
        max-width: 200px;
    }

    .main-icon {
        font-size: 200px;
    }


    .container-top {
        margin-bottom: 100px;
    }
}

@media screen and (min-width: 768px) {

    .main-img {
        max-width: 300px;
    }

    .main-icon {
        font-size: 300px;
        padding: 60px;
    }

    .container-top {
        height: 40%;
        margin-bottom: 200px;
    }
    
}

@media screen and (min-width: 1200px) {

    .split-content {
        display: grid;
        grid-template-columns: 330px auto;
    }
    
    .container-top {
        min-height: 100vh;
        height: 100%;
        margin: 0;
    }

    .container-bottom {
        min-height: 100vh;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: flex-start;
        padding: 150px 10px 50px 0;
        max-width: 1450px;
    }

    .main-img {
        max-width: 320px;
        position: absolute;
        top: calc(50vh - 80px - 200px);
        right: -160px;
    }
    
    .main-icon {
        font-size: 320px;
        padding: 80px;
    }
}

@media screen and (min-width: 1600px) {

    .main-img {
        max-width: 400px;
        right: -200px;
    }

    .main-icon {
        font-size: 400px;
        padding: 100px;
    }
}

