.about-cont {
    padding: 0 2rem;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.about-cont > article > p {
    margin-bottom: 2rem;
}

.typed-h1 {
    height: 150px;
}

.h1-typing {
    color: var(--btnbghover);
}

.h1-blink {
    color: var(--btnbghover);
    margin-left: 0.5rem;
    animation: blink-animation 1s steps(2, end) infinite;
    -webkit-animation: blink-animation 1s steps(2, end) infinite;
}

@keyframes blink-animation {
    0% {
        visibility: hidden;
      }
}

@-webkit-keyframes blink-animation {
    0% {
        visibility: hidden;
      }
}

@media screen and (min-width: 768px) {

    .about-cont {
        padding: 0 3rem;
    }

    .typed-h1 {
        height: 80px;
    }
}

@media screen and (min-width: 1200px) {

    .about-cont {
        padding: 20px 50px 0 220px;
        text-align: left;
        font-size: 110%;
    }

    .about-cont h1 {
        text-align: left;
    }
    
    .typed-h1 {
        height: 90px;
    }

}

@media screen and (min-width: 1600px) {

    .about-cont {
        padding: 20px 50px 0 280px;
    }

}